// components/Modal.js
import React, { useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';

const Modal = ({ isOpen, onClose, children, title }) => {
  const modalRef = useRef(null);

  useEffect(() => {
    const handleEscape = (event) => {
      if (event.key === 'Escape') {
        onClose();
      }
    };

    if (isOpen) {
      document.addEventListener('keydown', handleEscape);
      document.body.style.overflow = 'hidden';
    }

    return () => {
      document.removeEventListener('keydown', handleEscape);
      document.body.style.overflow = 'unset';
    };
  }, [isOpen, onClose]);

  const handleOutsideClick = (e) => {
    // If the click is on the overlay (the outer div) and not the modal content
    if (modalRef.current && !modalRef.current.contains(e.target)) {
      onClose();
    }
  };

  if (!isOpen) return null;

  return createPortal(
    <div
      className="fixed inset-0 z-50 flex items-center justify-center"
      onClick={handleOutsideClick}
    >
      {/* Semi-transparent overlay */}
      <div className="absolute inset-0 z-40 bg-gray-300 opacity-20" />

      {/* Modal content */}
      <div
        ref={modalRef}
        className="relative z-50 w-full max-w-md p-6 bg-white rounded-lg shadow-xl m-4"
        onClick={(e) => e.stopPropagation()} // Prevent clicks inside modal from closing it
      >
        {title && (
          <h2 className="text-xl font-bold mb-4">
            {title}
          </h2>
        )}
        {children}
      </div>
    </div>,
    document.body
  );
};

export default Modal;