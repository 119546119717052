// src/App.js
import React, { Suspense, lazy } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Navbar from './components/Navbar';

// Lazy load pages instead of direct imports
const HomePage = lazy(() => import('./feature/home/components/HomePage'));
const SubmissionForm = lazy(() => import('./feature/submissions/components/SubmissionForm'));
const SubmissionList = lazy(() => import('./feature/voting/components/SubmissionVoting'));

function App() {
  return (
    <BrowserRouter>
      <div className="app">
        <Navbar />
        <main>
          <Suspense fallback={<div>Loading...</div>}>
            <Routes>
              <Route path="/" element={<HomePage />} />
              <Route path="/submit" element={<SubmissionForm />} />
              <Route path="/vote" element={<SubmissionList />} />
            </Routes>
          </Suspense>
        </main>
      </div>
    </BrowserRouter>
  );
}

export default App;