// components/UserMenu.js
import React from 'react';
import { Link } from 'react-router-dom';

const UserMenu = ({ onClose }) => {
  const handleLogout = async () => {
    try {
      const response = await fetch('/api/members/logout/', {
        method: 'POST',
        headers: {
          'X-CSRFToken': window.djangoContext.csrfToken,
        },
      });
      if (response.ok) {
        window.location.href = '/';
      }
    } catch (error) {
      console.error('Logout failed:', error);
    }
  };

  const handleDeleteAccount = () => {
    if (window.confirm('Are you sure you want to delete your account? This action cannot be undone.')) {
      fetch('/api/members/delete-account/', {
        method: 'POST',
        headers: {
          'X-CSRFToken': window.djangoContext.csrfToken,
        },
      })
      .then(response => {
        if (response.ok) {
          window.location.href = '/';
        }
      })
      .catch(error => console.error('Delete account failed:', error));
    }
  };

  return (
    <div className="absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg py-1 z-50">
      <Link
        to="/profile"
        className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
      >
        Profile
      </Link>
      <button
        onClick={handleLogout}
        className="w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
      >
        Log Out
      </button>
      <button
        onClick={handleDeleteAccount}
        className="w-full text-left px-4 py-2 text-sm text-red-600 hover:bg-gray-100"
      >
        Delete Account
      </button>
    </div>
  );
};

export default UserMenu;