// feature/profiles/component/LogInPrompt.js
import React from 'react';

const LoginPrompt = ({ message = "Please log in to continue" }) => {
  const handleDiscordLogin = () => {
    const currentPath = window.location.pathname;
    window.location.href = `/oauth/login/discord/?next=${encodeURIComponent(currentPath)}`;
  };

  return (
    <div className="text-center">
      <h2 className="text-xl font-bold mb-4">{message}</h2>
      <button
        onClick={handleDiscordLogin}
        className="bg-[#5865F2] text-white px-6 py-2 rounded-md hover:bg-[#4752C4] transition-colors"
      >
        Login with Discord
      </button>
    </div>
  );
};

export default LoginPrompt;