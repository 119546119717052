import React, { Suspense, lazy } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import Navbar from './components/navbar/Navbar';

// Configure QueryClient with default options
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
      cacheTime: 0,
      staleTime: 0
    },
    mutations: {
      retry: false
    }
  }
});

// Lazy load pages
const HomePage = lazy(() => import('./pages/home/components/HomePage'));
const SubmissionFlow = lazy(() => import('./pages/submission'));
const SubmissionForm = lazy(() => import('./pages/submissions/components/SubmissionForm'));
const SubmissionList = lazy(() => import('./pages/voting/components/SubmissionVoting'));
const PricingPage = lazy(() => import('./pages/profiles/components/PricingPage'));
const ProfilePage = lazy(() => import('./pages/profiles/components/ProfilePage'));
const PlaylistPage = lazy(() => import('./pages/playlists/components/PlaylistPage'));
const ShopPage = lazy(() => import('./pages/shop/components/ShopPage'));

const ProfileWrapper = lazy(() => import('./pages/profiles/components/ProfileWrapper'));

function App() {
  const excludeBackendRoutes = ['/admin', '/auth', '/oauth'];
  const isBackendRoute = excludeBackendRoutes.some((route) =>
    window.location.pathname.startsWith(route)
  );

  if (isBackendRoute) {
    return null;
  }

  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <div className="app" style={{ backgroundColor: '#FAF9F6' }}>
          <Navbar />
          <main>
            <Suspense fallback={<div>Loading...</div>}>
              <Routes>
                <Route path="/" element={<HomePage />} />
                <Route path="/submissions" element={<SubmissionForm />} />
                <Route path="/vote" element={<SubmissionList />} />
                <Route path="/membership" element={<PricingPage />} />
                <Route path="/profile" element={<ProfileWrapper />} />
                <Route path="/playlists" element={<PlaylistPage />} />
                <Route path="/shop" element={<ShopPage />} />
              </Routes>
            </Suspense>
          </main>
        </div>
      </BrowserRouter>
    </QueryClientProvider>
  );
}

export default App;
