// components/Navbar.js
import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FaUserCircle } from 'react-icons/fa';
import Modal from './Modal';
import LoginPrompt from '../feature/profiles/component/LogInPrompt';
import UserMenu from './UserMenu';

const NavLink = ({ to, children, onMouseEnter }) => (
  <Link
    to={to}
    onMouseEnter={onMouseEnter}
    className="text-gray-900 hover:text-gray-700"
  >
    {children}
  </Link>
);

const SubMenuContent = ({ type }) => {
  const menuItems = {
    playlists: [
      { to: "/spotify", text: "spotify" },
      { to: "/apple-music", text: "apple music" },
      { to: "/youtube-music", text: "youtube music" }
    ],
    submissions: [
      { to: "/submit", text: "submit a track" },
      { to: "/vote", text: "submission voting" }
    ],
    community: [
      { to: "/discord", text: "discord" },
      { to: "/newsletter", text: "newsletter" },
      { to: "/submission-voting", text: "submission voting" },
      { to: "/playlist-voting", text: "playlist voting" }
    ]
  };

  return (
    <div className="flex flex-col space-y-1">
      {menuItems[type]?.map(item => (
        <Link key={item.to} to={item.to} className="text-xs font-barlow font-light">
          {item.text}
        </Link>
      ))}
    </div>
  );
};

const Navbar = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [activeSubmenu, setActiveSubmenu] = useState(null);
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [showUserMenu, setShowUserMenu] = useState(false);
  const userMenuRef = useRef(null);

  const isLoggedIn = window.djangoContext?.isAuthenticated || false;

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (userMenuRef.current && !userMenuRef.current.contains(event.target)) {
        setShowUserMenu(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="relative">
      {/* Main Navbar */}
      <div className="sticky top-0 z-50 bg-white border-b border-gray-150">
        <div className="max-w-7xl mx-auto px-4">
          <div className="flex justify-between items-center h-16">
            {/* Left side */}
            <div className="flex items-center">
              <Link to="/" className="flex items-center">
                <img src="/static/assets/logo.svg" alt="Logo" className="h-8 w-auto" />
                <span className="text-3xl font-black font-barlow ml-2 italic">
                  uzplaylists
                </span>
              </Link>

              {/* Navigation */}
              <nav className="hidden md:flex space-x-8 ml-8">
                {['playlists', 'submissions', 'community', 'shop'].map(item => (
                  <NavLink
                    key={item}
                    to={`/${item}`}
                    onMouseEnter={() => item !== 'shop' && setActiveSubmenu(item)}
                  >
                    {item}
                  </NavLink>
                ))}
              </nav>
            </div>

            {/* Right side */}
            <div className="flex items-center space-x-4">
              <Link to="/submit" className="hidden md:block">
                Submit Your Music
              </Link>
              {isLoggedIn ? (
                <div className="relative" ref={userMenuRef}>
                  <button
                    onClick={() => setShowUserMenu(!showUserMenu)}
                    className="p-1 rounded-full hover:bg-gray-100"
                  >
                    <FaUserCircle className="w-6 h-6" />
                  </button>
                  {showUserMenu && <UserMenu onClose={() => setShowUserMenu(false)} />}
                </div>
              ) : (
                <button
                  onClick={() => setShowLoginModal(true)}
                  className="text-black hover:text-gray-700"
                >
                  Log In
                </button>
              )}
              <button className="md:hidden" onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}>
                <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16" />
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Submenu */}
      {activeSubmenu && (
        <div
          className="absolute w-full bg-white shadow-md z-40"
          onMouseLeave={() => setActiveSubmenu(null)}
        >
          <div className="max-w-7xl mx-auto px-4 py-4">
            <SubMenuContent type={activeSubmenu} />
          </div>
        </div>
      )}

      {/* Mobile Menu */}
      <div
        className={`fixed inset-0 bg-white z-50 transform ${
          isMobileMenuOpen ? 'translate-x-0' : 'translate-x-full'
        } transition-transform duration-300`}
      >
        <div className="p-4">
          <div className="flex justify-end">
            <button onClick={() => setIsMobileMenuOpen(false)}>
              <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
          </div>
          <div className="mt-8 space-y-4">
            {['playlists', 'submissions', 'community', 'shop'].map(item => (
              <div key={item}>
                <h3 className="text-2xl font-barlow italic mb-2">{item}</h3>
                {item !== 'shop' && <SubMenuContent type={item} />}
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Login Modal */}
      <Modal
        isOpen={showLoginModal}
        onClose={() => setShowLoginModal(false)}
        title="Log In"
      >
        <LoginPrompt message="Log in to access all features" />
      </Modal>
    </div>
  );
};

export default Navbar;